import React from "react"
import { Layout2022Q3 } from "@layout"
import SEO from "@components/seo"
import { Hero4042022Q3 } from "@components/hero"

const NotFoundPage = () => (
  <Layout2022Q3 backgroundImage="animated" hero={Hero4042022Q3}>
    <SEO title="Page Not Found" />
  </Layout2022Q3>
)

export default NotFoundPage
